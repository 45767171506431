//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB_5_jA2pO-9P1peoEAWXr4_O5G55hHQBs",
  authDomain: "rymnews.firebaseapp.com",
  projectId: "rymnews",
  storageBucket: "rymnews.appspot.com",
  messagingSenderId: "160580943508",
  appId: "1:160580943508:web:9606c577d75e73ba23fe81",
  measurementId: "G-WC3ZVMSXE1",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
