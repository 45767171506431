import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ImageSlider = () => {
  const images = [
    require("../images/main.jpg"), // Add correct path for your images
    require("../images/main1.jpg"),
    require("../images/main2.jpg"),
    require("../images/main3.jpg"),
    require("../images/main4.jpg"),
    require("../images/main5.jpg"),
    require("../images/main6.jpg"),
  ];

  return (
    <div className="slider-container w-full bg-black py-10">
      <Swiper
        autoplay={{ delay: 3000 }} // Automatically slides every 3 seconds
        loop={true} // Enables infinite scrolling
        speed={1000} // Transition speed
        modules={[Autoplay]} // Add autoplay module
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="image-slide">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-auto object-cover rounded-md"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
