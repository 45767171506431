import React, { useState } from 'react';
import './Launchpad.css'; // Import the CSS for styling
import nft from '../images/nft.jpg';
import game from '../images/game.jpg';
import launch from '../images/launch.jpg';
import trade from '../images/trade.jpg';

const tabs = [
  {
    id: 1,
    title: 'NFT',
    description: 'Buy, Sell, and Create: Trade exclusive NFTs or showcase your creativity by minting your own NFT on $FIG Marketplace.',
    image: nft,
    buttonText: 'Continue',
  },
  {
    id: 2,
    title: 'Token',
    description: '$FIG Fam! will be able to create and deploy their own token easily.',
    image: launch,
    buttonText: 'Continue',
  },
  {
    id: 3,
    title: 'Exchange',
    description: 'Buy, sell, and trade your favorite cryptocurrencies with ease. $FIG Exchange Platform Coming!',
    image: trade,
    buttonText: 'Continue',
  },
  {
    id: 4,
    title: 'GameFi',
    description: 'Play-to-earn games powered by $FIG let you earn tokens as you have fun.',
    image: game,
    buttonText: 'Continue',
  },
  {
    id: 5,
    title: 'Stake and Earn',
    description: 'Stake your $FIG tokens to earn rewards with competitive rates.',
    image: nft,
    buttonText: 'Start Farming',
  },
];

const Launchpad = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleContinue = () => {
    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
    } else {
      // Redirect or perform action when the last tab is reached
      window.location.href = '/earn';
    }
  };

  return (
    <div className="launchpad-container bg-black min-h-screen flex flex-col items-center">
      {/* Header */}
      <br></br>
      <br></br>
      <div className="w-full text-center py-5 px-4">
        <h1 className="text-[28px] text-white font-semibold">
          $FIG  Coming Soon
        </h1>
        
      </div>
      
      {/* Horizontal Tabs Navigation */}
      <div className="tabs-navigation flex overflow-x-auto w-full px-4 space-x-4 scrollbar-hide">
        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(index)}
            className={`tab-button ${
              index === activeTab ? 'text-yellow-500 border-b-2 border-yellow-500' : 'text-gray-300'
            } py-2 text-[16px] whitespace-nowrap transition`}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Active Tab Content */}
      <div className="tab-content flex-grow w-full flex flex-col justify-center items-center">
        <div className="relative w-full h-[80vh]">
          <img
            src={tabs[activeTab].image}
            alt={tabs[activeTab].title}
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center text-center px-6">
            <h3 className="text-[20px] text-yellow-500 font-semibold">{tabs[activeTab].title}</h3>
            <p className="text-[14px] text-gray-300 mt-2">{tabs[activeTab].description}</p>
            <button
              className="bg-yellow-500 text-black px-6 py-2 mt-4 rounded-md text-[14px] hover:bg-yellow-400 transition"
              onClick={handleContinue}
            >
              {tabs[activeTab].buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Launchpad;
